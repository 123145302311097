:root {
    /* NFTchat */
    --main-color: #00fd00;
    --second-color: Black;
    --shadow-color: #c8c8c8;
    /* Ads 
    --main-color: black;
    --second-color: #f8f8f8; 
    --shadow-color: #00fd00;*/
    --third-color: rgb(149 149 149);
}

.App {
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
    text-align: center;
    background-color: var(--second-color);
    position: absolute;
    height: 100%;
    width: 100%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    color: var(--main-color)
}

.Title {
    font-family: monospace;
    margin: auto;
    text-align: left;
    margin-top: 5vh;
    margin-bottom: 3vh;
    font-size: 2.6vmin;
}

#toAddress {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    background: var(--second-color);
    color: var(--main-color);
    padding: 1%;
    resize: none;
    width: 70%;
    font-size: 1.4vmin;
    font-family: monospace;
    max-width: 736px;
}

#toAddress:focus {
    outline: 1px solid var(--main-color);
}

.toAddressLabel {
    width: 65%;
    font-family: monospace;
    text-align: left;
    font-size: 1.4vmin;
    display: inline-block;
}

.TextArea {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    background: var(--second-color);
    color: var(--main-color);
    padding: 1%;
    resize: none;
    width: 80%;
    min-height: 10%;
    font-size: 1.6vmin;
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
    max-width: 564px;
}

.TextArea:focus {
    outline: 1px solid var(--main-color);
}

#welcomeContainer {
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    background: var(--second-color);
    width: 82%;
    vertical-align: middle;
    display: flex;
    align-items: center;
    max-width: 610px;
    margin-bottom: 4%;
}

#welcomeMsg {
    display: flex;
    flex-direction: column;
    padding: 1%;
    text-align: left;
    align-items: center;
    justify-content: center;
    background: var(--second-color);
    font-size: 2.2vw;
    text-shadow: 0 0 4px var(--shadow-color);
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
    width: 100%;
}

#previewContainer {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    background: var(--second-color);
    width: 82%;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 540px;
}

#msgPreview {
    display: flex;
    flex-direction: column;
    padding: 2%;
    text-align: left;
    align-items: center;
    justify-content: center;
    background: var(--second-color);
    font-size: 5.6vmin;
    text-shadow: 0 0 4px var(--shadow-color);
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
    width: 100%;
    height: calc(100vw * 0.79);
    max-height: 519px;
    aspect-ratio: 1 / 1;
}

.container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-bottom: 3%;
    width: 82%;
    max-width: 584px;
    display: flex;
}

.Sociallinks {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    width: 83%;
    height: 6vh;
    max-width: 999px;
    text-align: right;
    font-size: 1.5vmin;
    text-decoration: none;
}

.clientInfo {
    width: 66%;
    font-family: monospace;
    text-align: left;
    font-size: 1.2vmin;
    display: inline-block;
}

.SendButton {
    width: 34%;
    height: 100%;
    font-size: 1.4vw;
    cursor: pointer;
    font-family: monospace;
    color: var(--main-color);
    background-color: var(--second-color);
}

.SendButton:hover {
    color: var(--second-color);
    background-color: var(--main-color);
}

.SubscribeButton {
    width: 34%;
    height: 100%;
    font-size: 1.4vw;
    cursor: pointer;
    font-family: monospace;
    color: var(--second-color);
    background-color: var(--main-color);
}

.SubscribeButton:hover {
    color: var(--main-color);
    background-color: var(--second-color);
}

.gradiend {
    width: 34%;
    height: 100%;
    font-size: 1.4vw;
    cursor: none;
    font-family: monospace;
    display: inline-block;
    background: linear-gradient(270deg, var(--main-color), var(--second-color));
    background-size: 400% 400%;
    -webkit-animation: AnimationName 2s ease infinite;
    -moz-animation: AnimationName 2s ease infinite;
    animation: AnimationName 2s ease infinite;
}

a:link,
a:visited {
    color: var(--main-color);
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5vmin;
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
    font-size: '2vmin';
    margin: auto;
    margin-top: 7%;
    transition: 0.5s;
}

a:hover,
a:active {
    color: var(--second-color);
    background-color: var(--main-color);
    font-weight: bold;
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@keyframes AnimationName {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}


/* TOAST */

.closebtn {
    margin-right: 2%;
    color: var(--second-color);
    font-weight: bold;
    float: right;
    font-size: 3vmin;
    /*line-height: 18px;*/
    cursor: pointer;
    transition: 0.3s;
}

.closebtn:hover {
    opacity: 0.5;
}

.closebtn.info {
    color: var(--second-color);
    font-weight: normal;
}

.version {
    font-family: monospace;
    text-align: right;
    font-size: 1vmin;
    width: 80%;
    margin-top: 3vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
}


/*
___________███████__________███___________________
__________█████████_________███___________________
_________███_____███________███___________________
_________███_____███___████████__████████_________
_________███████████__███___███__███______________
_________███████████__███___███__████████_________
_________███_____███__███___███_______███_________
_________███_____███___████████__████████_________
*/

.AdsTitle {
    margin: auto;
    margin-top: 1vh;
    margin-bottom: 3vh;
    font-size: 2.6vmin;
    text-align: left;
}

#adsMsgPreview {
    display: flex;
    flex-direction: column;
    padding: 2%;
    text-align: left;
    align-items: center;
    justify-content: center;
    background: var(--second-color);
    font-size: 2vmin;
    text-shadow: 0 0 4px var(--shadow-color);
    width: 100%;
    height: calc(100vw * 0.79);
    max-height: 519px;
    aspect-ratio: 1 / 1;
}

#targetAddresses {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    background: var(--second-color);
    color: var(--main-color);
    padding: 1%;
    resize: none;
    width: 90%;
    font-size: 1.4vmin;
    font-family: monospace;
    max-width: 736px;
}

#targetAddress:focuses {
    outline: 1px solid var(--main-color);
}

.containerAds {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-bottom: 3%;
    width: 82%;
    max-width: 584px;
    display: flex;
    justify-content: space-between;
    height: 5vmin;
}

.clientInfoAds {
    width: 60%;
    font-family: monospace;
    text-align: left;
    font-size: 1.6vmin;
    display: inline-block;
}

.collections {
    font-size: 2vmin;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 82%;
    max-width: 584px;
    border-width: 1px;
    border-style: solid;
}

.rmsc {
    --rmsc-main: var(--main-color) !important;
    --rmsc-hover: var(--third-color) !important;
    --rmsc-selected: var(--third-color) !important;
    --rmsc-border: var(--third-color) !important;
    --rmsc-gray: var(--third-color) !important;
    --rmsc-bg: var(--second-color) !important;
    --rmsc-p: 3vmin !important;
    /* Spacing */
    --rmsc-radius: 0px !important;
    /* Radius */
    --rmsc-h: 6vmin !important;
    /* Height */
}

.search {
    background-color: var(--main-color);
}

.dropdown-content {
    -webkit-font-smoothing: antialiased;
    text-align: center;
    color: var(--main-color);
    font-size: 2vmin;
    box-sizing: border-box;
    transition: all 0.2s ease;
    position: absolute;
    z-index: 1;
    top: auto !important;
    bottom: 100% !important;
    width: 100%;
    padding-top: 8px;
}

.fromToCointainer {
    font-size: 2vmin;
    margin: auto;
    padding: 0%;
    display: flex;
    width: 82%;
    justify-content: space-between;
    max-width: 560px;
    max-width: 585px;
}

.fromTo {
    font-size: 2vmin;
    font-weight: bold;
    height: 2vmin;
    background-color: var(--main-color);
    width: 8vmin;
    color: var(--second-color);
    border: none;
}

.totalTarget {
    margin: auto;
    margin-top: 1%;
    margin-bottom: 2%;
    font-size: 2vmin;
    text-align: end;
    width: 80%;
    max-width: 584px;
}